import React, { useState } from 'react';
import { Link } from 'gatsby';
import '../styles/main.scss';
import { navigate } from 'gatsby';
import useAuth from '../api/auth';
import travelIcon from '../images/viajar.svg';
import carIcon from '../images/car.svg';
import backgroundMap from '../images/backgroundMap.svg';
import CardStatus from '../components/card-status/card-status';
import ButtonService from '../components/button-service/button-service';
import '../styles/home.scss';
import {
  Typography,
  Layout,
  Menu,
  Card,
  Avatar,
  Row,
  Col,
  Image,
  Divider,
} from 'antd';
import { useSelector } from 'react-redux';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserAddOutlined,
  ClockCircleOutlined,
  CreditCardOutlined,
  ShareAltOutlined,
  PhoneOutlined,
  ContactsOutlined,
  SettingFilled,
  LogoutOutlined,
} from '@ant-design/icons';
const { Text } = Typography;

const { Meta } = Card;

const { Header, Sider, Content } = Layout;

const HomePage = () => {
  const { user } = useSelector(state => state);

  const [collapsed, setCollapsed] = useState(false);
  const { logout } = useAuth();

  const onLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        className="background-primary"
        trigger={null}
        collapsible
        collapsed={!collapsed}
        collapsedWidth="0"
        breakpoint="xs"
        width="250"
      >
        <Card className="background-primary-medium">
          <Meta
            avatar={
              <Avatar
                size="large"
                src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
              />
            }
            title={user.fullname}
            description={user.email}
          />
        </Card>
        <Menu
          theme="dark"
          className="menu-items background-primary"
          mode="inline"
          defaultSelectedKeys={['1']}
        >
          <Menu.Item key="1" icon={<UserAddOutlined />}>
            <Link to="/profile">Cuenta</Link>
          </Menu.Item>
          <Menu.Item key="2" icon={<ClockCircleOutlined />}>
            <Link to="/shopping-summary">Resumen de compras</Link>
          </Menu.Item>
          <Menu.Item key="3" icon={<ContactsOutlined />}>
            <Link to="/user-contacts">Contactos de emergencia</Link>
          </Menu.Item>
          <Menu.Item key="4" icon={<CreditCardOutlined />}>
            <Link to="/method-payment">Medios depago</Link>
          </Menu.Item>
          <Menu.Item key="5" icon={<ShareAltOutlined />}>
            <Link to="/">Compartir</Link>
          </Menu.Item>
          <Menu.Item key="6" icon={<PhoneOutlined />}>
            <Link to="/">Call center</Link>
          </Menu.Item>
          <Menu.Item key="7" icon={<LogoutOutlined />}>
            <a onClick={() => onLogout()}>Cerrar Sesión</a>
          </Menu.Item>
        </Menu>
        <Link to="/">
          <SettingFilled className="settins-icon icon-color-base" />
        </Link>
      </Sider>
      <Layout className="site-layout" >
        <Header
          style={{
            backgroundImage: `url(${backgroundMap})`,
            padding: 0,
          }}
        >
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: 'icon-menu-size icon-color-primary',
              onClick: () => setCollapsed(!collapsed),
            }
          )}
          <Text className="title-section" style={{ paddingLeft: 20 }} strong>
            ¿CÓMO TE VAMOS A PROTEGER HOY?
          </Text>
        </Header>
        <Content style={{ minHeight: '76vh', display: 'grid' }}>
          <Image
            preview={false}
            style={{ position: 'fixed' }}
            src={backgroundMap}
            width="100%"
          />
          <div className="site-layout-background">
            <Divider />
            <Row gutter={[16, 24]}>
              <Col span={12}>
                <ButtonService
                  icon={travelIcon}
                  title="Viajar seguro"
                  goTo="/wizard"
                />
              </Col>

              <Col span={12}>
                <ButtonService
                  icon={carIcon}
                  title="Asegurar vehículo"
                  goTo="/"
                />
              </Col>
            </Row>
            <Text className="text-primary">Seguros activos</Text> <br />
            <Row gutter={[16, 24]}>
              <Col span={24}>
                <CardStatus
                  title="Jan 27, 2020 - 1:45 PM"
                  description="DE Loja, Terminal terrestre A Cuenca"
                  status="ACTIVO"
                  goTo="/home"
                  typeButton="primary"
                />
              </Col>
            </Row>
            <Row justify="space-between">
              <Text className="text-primary">Seguros anteriores</Text>
              <Link to="/">ver todos</Link>
            </Row>
            <Row>
              <Col span={24}>
                <CardStatus
                  title="Jan 27, 2020 - 1:45 PM"
                  description="DE Loja, Terminal terrestre A Cuenca"
                  status="FINALIZADO"
                  goTo="/"
                  typeButton="dashed"
                />
              </Col>
            </Row>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default HomePage;
